<template>
  <div class="main">
    <el-form :model="form"
             ref="formRef">
      <el-row :gutter="10">
        <el-col :lg="8"
                :md="12"
                :sm="12"
                :xl="8"
                :xs="24">
          <el-button type="primary"
                     icon="el-icon-plus"
                     style="border-radius: 2px;width: 84px;height: 32px;margin-bottom: 6px;background: #3f9eff !important;"
                     @click="toAdd">新增</el-button>
          <el-button icon="el-icon-delete"
                     style="border-radius: 2px;width: 112px;height: 32px;"
                     @click="allDelete">批量删除</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table border
              highlight-current-row
              type=""
              :stripe="true"
              :max-height="$store.state.tableHeight + 40 "
              :data="tableData"
              @select="rowSelect"
              @select-all="selectAll"
              style="width: 100%;">
      <el-table-column type="selection"
                       width="40">
      </el-table-column>
      <el-table-column prop="detection_id"
                       align="left"
                       label="检测编号" />
      <el-table-column prop="detection_name"
                       align="left"
                       label="检测报告" />

      <el-table-column prop="subject_name"
                       align="left"
                       label="科目" />
      <el-table-column prop="create_time"
                       align="left"
                       sortable
                       label="创建时间" />
      <el-table-column prop="name"
                       align="left"
                       fixed="right"
                       label="操作">
        <template slot="header">
          操作
        </template>

        <template slot-scope="{row}">

          <div class="text_btn_primary"
               @click="showDialog(row)">数据中心</div>

          <div class="text_btn_primary"
               @click="toViewPaper(row)">查看试卷</div>
          <div class="text_btn_primary"
               @click="updateInfo(row)">修改</div>

          <div class="text_btn_danger"
               @click="deleteInfo(row)">删除</div>

        </template>

      </el-table-column>
    </el-table>
    <!-- <Shuttle ref="ShuttleRef" /> -->
    <pagination ref="pagination"
                :get-data="initData"
                
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <centerDialog ref="DialogRef" />
  </div>
</template>

<script>

import centerDialog from "@/views/dataAnalysis/components/centerDialog.vue"
import { delDetection, addTestReport } from '@/api/dataAnalysis.js'
export default {
  name: 'checkTest',
  data () {
    return {
      form: {
        user_id: '',
        subject_id: '',
        ShowOnlyCanBeChecked: 'on',
        ShowTestUser: '',
        page: 1,
        limit: 10
      },
      suubjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      rowSelectData: []
    }
  },
  components: {
    centerDialog
  },
  created () {

  },
  mounted () {
    // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
    // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
    // // document.getElementsByClassName('view_warp')[0].style.background = '#FFFFFF'
    // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
    // document.getElementsByClassName('view_warp')[0].style.marginTop = '34px'
    // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '110px'
    // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'
    // window.onpopstate = function (event) {
    //   location.reload(); // 当路由回退时重新加载整个页面
    // };

  },
  methods: {
    async allDelete () {

      this.$confirm("确定批量删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/tang/del_detection',
          method: 'post',
          data: {
            detection_id: this.rowSelectData,
            user_paper_id: window.localStorage.getItem('user_id'),
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.refresh()
        })
      })


    },
    async updateInfo (row) {
      console.log('rew', row)
      this.$router.push(
        {
          path: '/dataAnalysis/edit',
          query: {
            user_id: this.$route.query.user_id || window.localStorage.getItem('user_id'),
            subject_name: row.subject_name,
            detection_name: row.detection_name,
            detection_id: row.detection_id
          }
        }
      )
    },
    selectAll (row) {
      console.log('row', row)
      this.rowSelectData = []
      row.map(item => {
        this.rowSelectData.push(item.detection_id)
      }
      )
    },
    rowSelect (row) {
      this.rowSelectData = []
      row.map(item => {
        this.rowSelectData.push(item.detection_id)
      }
      )
    },
    toViewPaper (row) {
      window.localStorage.setItem('detection_id', row.detection_id)
      window.localStorage.setItem('user_paper_id', window.localStorage.getItem('user_id'))
      window.localStorage.setItem('data_grade_id', row.grade_id)
      console.log('943', window.localStorage.getItem('user_id'))
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper',
          query: {
            detection_id: row.detection_id,
            subject_id: row.subject_id,
            grade_id:row.grade_id
          }
        })
    },
    async showDialog (row) {
      console.log('dsf', row.detection_id)
      this.$refs.DialogRef.detection_id = row.detection_id
      this.$refs.DialogRef.openDialog()
      this.$refs.DialogRef.dialogVisible = true
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      // console.log('sss', this.$route.query.user_id)
      this.form.user_id = this.$route.query.user_id || window.localStorage.getItem('user_id')
      this.$http({
        url: '/api/v1/tang/detection_list',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.suubjectList = res.data.subject
      })
    },
    toAdd () {
      this.$router.push(
        {
          path: '/dataAnalysis/addTest',
          query: {
            user_id: this.$route.query.user_id || window.localStorage.getItem('user_id'),
          }
        }
      )
    },
    toPage (row) {
      this.$router.push('/checkTestAll/checkTestPage?id=' + row.user_paper_id + '&judge_status=' + row.judge_status)
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    async deleteInfo (row) {
      console.log('row', row)
      let detection_id = []
      detection_id.push(row.detection_id)

      this.$confirm("确定将其删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/tang/del_detection',
          method: 'post',
          data: {
            detection_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.refresh()
        })
      })


    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  font-family: Regular;
  padding: 12px 12px 0 12px;
  background: white;
  margin-bottom: 15px;
}
::v-deep .el-row {
  margin-bottom: 16px;
}
::v-deep .text_btn_primary {
  color: #3f9eff;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}

.pedding {
  color: green;
}
</style>